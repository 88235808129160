import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionics"></a><h2>Psionics</h2>
    <a id="psionic-powers-overview"></a><h3>PSIONIC POWERS OVERVIEW</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Psionic powers spring from sentient minds. Even an undead creature or a
being that has no physical form can create a reserve of inner strength
necessary to manifest powers, as long as it has an Intelligence score
of at least 1. Vermin possessed of a hive mind ability are an exception
to this rule.
    <p>A psionic power is a one-time psionic effect. Psionic
characters and creatures need not prepare their powers for use ahead of
time. They either have sufficient power points to manifest a power or
they do not.</p>
    <p>A power is manifested when a psionic character pays its power
point cost. Some psionic creatures automatically manifest powers,
called psi-like abilities, without paying a power point cost. Other
creatures pay power points to manifest their powers, just as characters
do.</p>
    <p>Each power has a specific effect. A power known to a psionic
character can be used whenever he or she has power points to pay for it.</p>
    <a id="manifesting-powers"></a><h6>MANIFESTING POWERS</h6>
Psionic characters and creatures manifest powers. Whether they cost
power points when manifest by a psionic character, or are manifested as
psi-like abilities, powers&rsquo; effects remain the same. The process
of
manifesting a power is akin to casting a spell, but with significant
differences.
    <a id="choosing-a-power"></a><h6>CHOOSING A POWER</h6>
First you must choose which power to manifest. You can select any power
you know, provided you are capable of manifesting powers of that level
or higher. To manifest a power, you must pay power points, which count
against your daily total. You can manifest the same power multiple
times if you have points left to pay for it.
    <a id="concentration"></a><h6>CONCENTRATION</h6>
To manifest a power, you must concentrate. If something threatens to
interrupt your concentration while you&rsquo;re manifesting a power,
you must
succeed on a <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> check or lose the
power points without
manifesting the power. The more distracting the interruption and the
higher the level of the power that you are trying to manifest, the
higher the DC. (Higher-level powers require more mental effort.)
    <a id="concentration-injury"></a><p><span style={{
        "fontWeight": "bold"
      }}>Injury:</span> Getting hurt
or being affected by hostile psionics while trying to manifest a power
can break your concentration and ruin a power. If you take damage while
trying to manifest a power, you must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 10
+ points of damage taken + the level of the power you&rsquo;re
manifesting).
The interrupting event strikes during manifestation if it occurs
between when you start and when you complete manifesting a power (for a
power with a manifesting time of 1 round or longer) or if it comes in
response to your manifesting the power (such as an attack of
opportunity provoked by the manifesting of the power or a contingent
attack from a readied action).
If you are taking continuous damage half the damage is considered to
take place while you are manifesting a power. You must make a
Concentration check (DC 10 + 1/2 the damage that the continuous source
last dealt + the level of the power you&rsquo;re manifesting).
If the last damage dealt was the last damage that the effect could deal
then the damage is over, and it does not distract you.
Repeated damage does not count as continuous damage.</p>
    <a id="concentration-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Power:</span> If you are
affected by a power while attempting to manifest a power of your own,
you must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check or lose the
power you are
manifesting. If the power affecting you deals damage, the Concentration
DC is 10 + points of damage + the level of the power you&rsquo;re
manifesting. If the power interferes with you or distracts you in some
other way, the Concentration DC is the power&rsquo;s save DC + the
level of
the power you&rsquo;re manifesting. For a power with no saving throw,
it&rsquo;s
the DC that the power&rsquo;s saving throw would have if a save were
allowed.</p>
    <a id="concentration-grappling-or-pinned"></a><p><span style={{
        "fontWeight": "bold"
      }}>Grappling or Pinned:</span>
To manifest a power while grappling or pinned, you must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 20 + the
level of the power you&rsquo;re manifesting)
or lose the power.</p>
    <a id="concentration-vigorous-motion"></a><p><span style={{
        "fontWeight": "bold"
      }}>Vigorous Motion:</span> If
you are riding on a moving mount, taking a bouncy ride in a wagon, on a
small boat in rough water, belowdecks in a storm-tossed ship, or simply
being jostled in a similar fashion, you must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check
(DC 10 + the level of the power you&rsquo;re manifesting) or lose the
power.</p>
    <a id="concentration-violent-motion"></a><p><span style={{
        "fontWeight": "bold"
      }}>Violent Motion:</span> If you
are on a galloping horse, taking a very rough ride in a wagon, on a
small boat in rapids or in a storm, on deck in a storm-tossed ship, or
being tossed roughly about in a similar fashion, you must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 15 + the
level of the power you&rsquo;re manifesting)
or lose the power.</p>
    <a id="concentration-violent-weather"></a><p><span style={{
        "fontWeight": "bold"
      }}>Violent Weather:</span> If
you are in a high wind carrying blinding rain or sleet, the DC is 5 +
the level of the power you&rsquo;re manifesting. If you are in
wind-driven
hail, dust, or debris, the DC is 10 + the level of the power
you&rsquo;re
manifesting. In either case, you lose the power if you fail the <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check. If the
weather is caused by a power, use the rules
in the Power subsection above.</p>
    <a id="manifesting-powers-on-the-defensive"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manifesting Powers on the
Defensive:</span> If you want to manifest a power without provoking
attacks of opportunity, you need to dodge and weave. You must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 15 + the
level of the power you&rsquo;re manifesting)
to succeed. You lose the power points without successful manifestation
if you fail.</p>
    <a id="concentration-entangled"></a><p><span style={{
        "fontWeight": "bold"
      }}>Entangled:</span> If you want
to manifest a power while <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangled</a> in a net or while
affected by a
power with similar effects you must make a DC 15 <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check to
manifest the power. You lose the power if you fail.</p>
    <a id="manifester-level"></a><h6>MANIFESTER LEVEL</h6>
The variables of a power&rsquo;s effect often depend on its manifester
level,
which is equal to your psionic class level. A power that can be
augmented for additional effect is also limited by your manifester
level (you can&rsquo;t spend more power points on a power than your
manifester level). See Augment under Descriptive Text, below.
You can manifest a power at a lower manifester level than normal, but
the manifester level must be high enough for you to manifest the power
in question, and all level-dependent features must be based on the same
manifester level.
In the event that a class feature or other special ability provides an
adjustment to your manifester level, this adjustment applies not only
to all effects based on manifester level (such as range, duration, and
augmentation potential) but also to your manifester level check to
overcome your target&rsquo;s power resistance and to the manifester
level
used in dispel checks (both the dispel check and the DC of the check).
    <a id="power-failure"></a><h6>POWER FAILURE</h6>
If you try to manifest a power in conditions where the characteristics
of the power (range, area, and so on) cannot be made to conform, the
manifestation fails and the power points are wasted.
Powers also fail if your concentration is broken (see Concentration,
above).
    <a id="the-powers-result"></a><h6>THE POWER&rsquo;S RESULT</h6>
Once you know which creatures (or objects or areas) are affected, and
whether those creatures have made successful saving throws (if any were
allowed), you can apply whatever results a power entails.
    <a id="special-power-effects"></a><h6>SPECIAL POWER EFFECTS</h6>
Certain special features apply to all powers.
    <a id="attacks"></a><p><span style={{
        "fontWeight": "bold"
      }}>Attacks:</span> Some powers
refer to attacking. All offensive combat actions, even those that
don&rsquo;t
damage opponents, such as <a href="specialAttacks.html#disarm" style={{
        "color": "rgb(87, 158, 182)"
      }}>disarm</a> and <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull
rush</a>, are considered attacks.
All powers that opponents can resist with saving throws, that deal
damage, or that otherwise harm or hamper subjects are considered
attacks. Astral construct and similar powers are not considered attacks
because the powers themselves don&rsquo;t harm anyone.</p>
    <a id="bonus-types"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bonus Types:</span> Many
powers give creatures bonuses to ability scores, Armor Class, attacks,
and other attributes. Each bonus has a type that indicates how the
power grants the bonus. The important aspect of bonus types is that two
bonuses of the same type don&rsquo;t generally stack. With the
exception of
dodge bonuses, most circumstance bonuses, and racial bonuses, only the
better bonus works (see Combining Psionic and Magical Effects, below).
The same principle applies to penalties - a character taking two or
more
penalties of the same type applies only the worst one.</p>
    <a id="bringing-back-the-dead"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bringing Back the Dead:</span>
Various psionic powers, such as <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a> and <a style={{
        "color": "#579eb6"
      }} href="psionicPowersGtoP.html#psionic-revivify">psionic
revivify</a>, have the ability to restore slain characters to life.
When a living creature dies, its soul
departs the body, leaves the Material Plane, travels through the Astral
Plane, and goes to abide on the plane where the creature&rsquo;s deity
resides. If the creature did not worship a deity, its soul departs to
the plane corresponding to its alignment. Bringing someone back from
the dead means retrieving his or her soul and returning it to his or
her body.</p>
    <a id="bringing-back-the-dead-level-loss"></a><p><span style={{
        "fontStyle": "italic"
      }}>Level Loss:</span> The
passage from life to death and back again is a wrenching journey for a
being&rsquo;s soul. Consequently, any creature brought back to life
usually
loses one level of experience. The character&rsquo;s new experience
point
total is midway between the minimum needed for his or her new (reduced)
level and the minimum needed for the next one. If the character was 1st
level at the time of death, he or she loses 2 points of Constitution
instead of losing a level. This level loss or Constitution loss cannot
be repaired by any mortal means, even the spells <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish</a>
or <a href="spellsMtoO.html#miracle" style={{
        "color": "rgb(87, 158, 182)"
      }}>miracle</a>.
A
revived character can regain a lost level by earning XP through further
adventuring. A revived character who was 1st level at the time of death
can regain lost points of Constitution by improving his or her
Constitution score when he or she attains a level that allows an
ability score increase.</p>
    <a id="preventing-revivification"></a><p><span style={{
        "fontStyle": "italic"
      }}>Preventing Revivification:</span>
Enemies can take steps to make it more difficult for a character to be
returned from the dead. Keeping the body prevents others from using a
single manifestation of <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a> to restore the
slain character
to life.</p>
    <a id="revivification-against-ones-will"></a><p><span style={{
        "fontStyle": "italic"
      }}>Revivification Against
One&rsquo;s
Will:</span> A soul cannot be returned to life if it does not wish to
be. A soul knows the name, alignment, and patron deity (if any) of the
character attempting to revive it and may refuse to return on that
basis.</p>
    <a id="combining-psionic-and-magical-effects"></a><h6>COMBINING PSIONIC AND MAGICAL EFFECTS</h6>
The default rule for the interaction of psionics and magic is simple:
Powers interact with spells and spells interact with powers in the same
way a spell or normal spell-like ability interacts with another spell
or spell-like ability. This is known as psionics-magic transparency.
    <a id="psionic-magic-transparency"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psionics-Magic Transparency:</span>
Though not explicitly called out in the spell descriptions or magic
item descriptions, spells, spell-like abilities, and magic items that
could potentially affect psionics do affect psionics.
When the rule about psionics-magic transparency is in effect, it has
the following ramifications.
Spell resistance is effective against powers, using the same mechanics.
Likewise, power resistance is effective against spells, using the same
mechanics as spell resistance. If a creature has one kind of
resistance, it is assumed to have the other. (The effects have similar
ends despite having been brought about by different means.)
All spells that dispel magic have equal effect against powers of the
same level using the same mechanics, and vice versa.
The spell <a href="spellsDtoE.html#detect-magic" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect magic</a> detects powers,
their number, and their strength
and location within 3 rounds (though a <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a>
check is necessary to
identify the discipline of the psionic aura).
Dead magic areas are also dead psionics areas.</p>
    <a id="multiple-effects"></a><p><span style={{
        "fontWeight": "bold"
      }}>Multiple Effects:</span>
Powers or psionic effects usually work as described no matter how many
other powers, psionic effects, spells, or magical effects happen to be
operating in the same area or on the same recipient. Except in special
cases, a power does not affect the way another power or spell operates.
Whenever a power has a specific effect on other powers or spells, the
power description explains the effect (and vice versa for spells that
affect powers). Several other general rules apply when powers, spells,
magical effects, or psionic effects operate in the same place.</p>
    <a id="stacking-effects"></a><p><span style={{
        "fontWeight": "bold"
      }}>Stacking Effects:</span>
Powers that provide bonuses or penalties on attack rolls, damage rolls,
saving throws, and other attributes usually do not stack with
themselves. More generally, two bonuses of the same type don&rsquo;t
stack
even if they come from different powers, or one from a power and one
from a spell. You use whichever bonus gives you the better result. </p>
    <a id="different-bonus-types"></a><p><span style={{
        "fontStyle": "italic"
      }}>Different Bonus Types:</span>
The bonuses or penalties from two different powers, or a power and a
spell, stack if the effects are of different types. A bonus that
isn&rsquo;t
named (just a &ldquo;+2 bonus&rdquo; rather than a &ldquo;+2 insight
bonus&rdquo;) stacks with
any bonus.</p>
    <a id="same-effect-more-than-once-in-different-strengths"></a><p><span style={{
        "fontStyle": "italic"
      }}>Same Effect More than Once
in Different Strengths:</span> In cases when two or more similar or
identical effects are operating in the same area or on the same target,
but at different strengths, only the best one applies. If one power or
spell is dispelled or its duration runs out, the other power or spell
remains in effect (assuming its duration has not yet expired).</p>
    <a id="same-effect-with-differing-results"></a><p><span style={{
        "fontStyle": "italic"
      }}>Same Effect with Differing
Results:</span> The same power or spell can sometimes produce varying
effects if applied to the same recipient more than once. The last
effect in a series trumps the others. None of the previous spells or
powers are actually removed or dispelled, but their effects become
irrelevant while the final spell or power in the series lasts.</p>
    <a id="one-effect-makes-another-irrelevant"></a><p><span style={{
        "fontStyle": "italic"
      }}>One Effect Makes Another
Irrelevant:</span> Sometimes, a power can render another power
irrelevant.</p>
    <a id="multiple-mental-control-effects"></a><p><span style={{
        "fontStyle": "italic"
      }}>Multiple Mental Control
Effects:</span> Sometimes psionic or magical effects that establish
mental control render one another irrelevant. Mental controls that
don&rsquo;t remove the recipient&rsquo;s ability to act usually do not
interfere
with one another, though one may modify another. If a creature is under
the control of two or more creatures, it tends to obey each to the best
of its ability, and to the extent of the control each effect allows. If
the controlled creature receives conflicting orders simultaneously, the
competing controllers must make opposed Charisma checks to determine
which one the creature obeys.</p>
    <a id="powers-and-spells-with-opposite-effects"></a><p><span style={{
        "fontWeight": "bold"
      }}>Powers and Spells with
Opposite Effects:</span> Powers and spells with opposite effects apply
normally, with all bonuses, penalties, or changes accruing in the order
that they apply. Some powers and spells negate or counter each other.
This is a special effect that is noted in a power&rsquo;s or
spell&rsquo;s
description.</p>
    <a id="instantaneous-effects"></a><p><span style={{
        "fontWeight": "bold"
      }}>Instantaneous Effects:</span>
Two or more magical or psionic effects with instantaneous durations
work cumulatively when they affect the same object, place, or creature.
    </p>
    <a id="powers-and-power-points"></a><h3>POWERS AND POWER POINTS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Psionic characters manifest powers, which involve the direct
manipulation of personal mental energy. These manipulations require
natural talent and personal meditation. A psionic character&rsquo;s
level
limits the number of power points available to manifest powers. A
psionic character&rsquo;s relevant high score might allow him to gain
extra
power points. He can manifest the same power more than once, but each
manifestation subtracts power points from his daily limit. Manifesting
a power is an arduous mental task. To do so, a psionic character must
have a key ability score of at least 10 + the power&rsquo;s level.
    <a id="daily-power-point-acquisition"></a><p><span style={{
        "fontWeight": "bold"
      }}>Daily Power Point Acquisition:</span>
To regain used daily power points, a psionic character must have a
clear mind. To clear his mind, he must first sleep for 8 hours. The
character does not have to slumber for every minute of the time, but he
must refrain from movement, combat, manifesting powers, skill use,
conversation, or any other demanding physical or mental task during the
rest period. If his rest is interrupted, each interruption adds 1 hour
to the total amount of time he has to rest to clear his mind, and he
must have at least 1 hour of rest immediately prior to regaining lost
power points. If the character does not need to sleep for some reason,
he still must have 8 hours of restful calm before regaining power
points.</p>
    <a id="recent-manifesting-limit-rest-interruptions"></a><p><span style={{
        "fontWeight": "bold"
      }}>Recent Manifesting Limit/Rest
Interruptions:</span> If a psionic character has manifested powers
recently, the drain on his resources reduces his capacity to regain
power points. When he regains power points for the coming day, all
power points he has used within the last 8 hours count against his
daily limit.</p>
    <a id="peaceful-environment"></a><p><span style={{
        "fontWeight": "bold"
      }}>Peaceful Environment:</span>
To regain power points, a psionic character must have enough peace,
quiet, and comfort to allow for proper concentration. The psionic
character&rsquo;s surroundings need not be luxurious, but they must be
free
from overt distractions, such as combat raging nearby or other loud
noises. Exposure to inclement weather prevents the necessary
concentration, as does any injury or failed saving throw the character
might incur while concentrating on regaining power points.</p>
    <a id="regaining-power-points"></a><p><span style={{
        "fontWeight": "bold"
      }}>Regaining Power Points:</span>
Once the character has rested in a suitable environment, it takes only
an act of concentration spanning 1 full round to regain all power
points of the psionic character&rsquo;s daily limit. </p>
    <a id="death-and-power-points"></a><p><span style={{
        "fontWeight": "bold"
      }}>Death and Power Points:</span>
If a character dies, all daily power points stored in his mind are
wiped away. A potent effect (such as <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a>) can recover
the
lost power points when it recovers the character.</p>
    <a id="adding-powers"></a><h6>ADDING POWERS</h6>
Psionic characters can learn new powers when they attain a new level. A
psion can learn any power from the <a href="PsionicPsionList.html" style={{
      "color": "rgb(87, 158, 182)"
    }}>psion/wilder list</a> and powers
from
his chosen <a href="psionicDisciplineList.html" style={{
      "color": "rgb(87, 158, 182)"
    }}>discipline&rsquo;s list</a>. A
wilder can
learn any power from the
psion/wilder list. A psychic warrior can learn any power from the <a href="PsionicWarriorList.html" style={{
      "color": "rgb(87, 158, 182)"
    }}>psychic
warrior list</a>.
    <a id="powers-gained-at-a-new-level"></a><p><span style={{
        "fontWeight": "bold"
      }}>Powers Gained at a New Level:</span>
Psions and other psionic characters perform a certain amount of
personal meditation between adventures in an attempt to unlock latent
mental abilities. Each time a psionic character attains a new level, he
or she learns additional powers according to his class description.
Psions, psychic warriors, and wilders learn new powers of their choice
in this fashion. These powers represent abilities unlocked from
latency. The powers must be of levels the characters can manifest.</p>
    <a id="independent-research"></a><p><span style={{
        "fontWeight": "bold"
      }}>Independent Research:</span>
A psion also can research a power independently, duplicating an
existing power or creating an entirely new one. If characters are
allowed to develop new powers, use these guidelines to handle the
situation.
Any kind of manifester can create a new power. The research involved
requires access to a retreat conducive to uninterrupted meditation.
Research involves an expenditure of 200 XP per week and takes one week
per level of the power. At the end of that time, the character makes a <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a>
check (DC 10 + spell
level). If that check succeeds, the
character learns the new power if her research produced a viable power.
If the check fails, the character must go through the research process
again if she wants to keep trying.</p>
    <a id="manifest-an-unknown-power-from-anothers-powers-known"></a><span style={{
      "fontWeight": "bold"
    }}>Manifest an Unknown Power from
Another&rsquo;s Powers Known</span>
A psionic character can attempt to manifest a power from a source other
than his own knowledge (usually a power stone or another willing
psionic character). To do so, the character must first make contact (a
process similar to addressing a power stone, requiring a <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>
check
against a DC of 15+ the highest level power in the power stone or
repertorie). A psionic
character can make contact with only a willing psionic character or
creature (unconscious creatures are considered willing, but not psionic
characters under the effects of other immobilizing conditions).
Characters who can&rsquo;t use power stones for any reason are also
banned
from attempting to manifest powers from the knowledge of other psionic
characters. Mental contact requires 1 full round of physical contact,
which can provoke attacks of opportunity. Once contact is achieved, the
character becomes aware of all the powers stored in the power stone or
all the powers the other character knows up to the highest level of
power the contactor knows himself. 
Next, the psionic character must choose one of the powers and make a
second Psicraft check (DC 15 + the power&rsquo;s level) to see if he
understands it. If the power is not on his class list, he automatically
fails this check.
Upon successfully making contact with another willing psionic character
or creature and learning what he can of one power in particular, the
character can immediately attempt to manifest that power even if he
doesn&rsquo;t know it (and assuming he has power points left for the
day). He
can attempt to manifest the power normally on his next turn, and he
succeeds if he makes one additional Psicraft check (DC 15 + the
power&rsquo;s
level). He retains the ability to manifest the selected power for only
1 round. If he doesn&rsquo;t manifest the power, fails the Psicraft
check, or
manifests a different power, he loses his chance to manifest that power
for the day.
    <a id="using-stored-power-points"></a><h6>USING STORED POWER POINTS</h6>
    <p className="initial">A variety of psionic items exist to store
power points for later use, in particular a storage device called a
cognizance crystal. Regardless of what sort of item stores the power
points, all psionic characters must follow strict rules when tapping
stored power points.
    </p>
    <a id="a-single-source"></a><p><span style={{
        "fontWeight": "bold"
      }}>A Single Source:</span> When
using power points from a storage item to manifest a power, a psionic
character may not pay the power&rsquo;s cost with power points from
more than
one source. He must either use an item, his own power point reserve, or
some other discrete power point source to pay the manifestation cost. </p>
    <a id="recharging"></a><p><span style={{
        "fontWeight": "bold"
      }}>Recharging:</span> Most power
point storage devices allow psionic characters to
&ldquo;recharge&rdquo; the item
with their own power points. Doing this depletes the character&rsquo;s
power
point reserve on a 1-for-1 basis as if he had manifested a power;
however, those power points remain indefinitely stored. The opposite is
not true-psionic characters may not use power points stored in a
storage item to replenish their own power point reserves.</p>
    <a id="special-abilities"></a><h3>SPECIAL ABILITIES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Psionic creatures can create psionic effects
without having levels in a psionic class (although they can take a
psionic class to further enhance their abilities); such creatures have
the psionic subtype.
    </p>
    <p>Characters using dorjes, cognizance crystals, and other
psionic items can also create psionic effects. In addition to existing
spell-like and supernatural abilities, creatures can also have psi-like
abilities. (Psionic creatures may also have extraordinary and natural
abilities.)</p>
    <a id="psi-like-abilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psi-Like Abilities (Ps):</span>
The manifestation of powers by a psionic character is considered a
psi-like ability, as is the manifestation of powers by creatures
without a psionic class (creatures with the psionic subtype, also
simply called psionic creatures). Usually, a psionic creature&rsquo;s
psi-like ability works just like the power of that name. A few psi-like
abilities are unique; these are explained in the text where they are
described.
Psi-like abilities have no verbal, somatic, or material components, nor
do they require a focus or have an XP cost (even if the equivalent
power has an XP cost). The user activates them mentally. Armor never
affects a psi-like ability&rsquo;s use. A psi-like ability has a
manifesting
time of 1 standard action unless noted otherwise in the ability
description. In all other ways, a psi-like ability functions just like
a power. However, a psionic creature does not have to pay a psi-like
ability&rsquo;s power point cost.
Psi-like abilities are subject to power resistance and to being
dispelled by <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a>. They do not
function in areas where
psionics is suppressed or negated.</p>
    <a id="supernatural-abilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Supernatural Abilities:</span>
Some creatures have psionic abilities that are considered supernatural.
Psionic feats are also supernatural abilities. These abilities cannot
be disrupted in combat, as powers can be, and do not provoke attacks of
opportunity (except as noted in their descriptions). Supernatural
abilities are not subject to power resistance and cannot be negated or
dispelled; however, they do not function in areas where psionics is
suppressed.
    </p>
    <a id="psionic-maladies"></a><h3>PSIONIC MALADIES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="ability-burn"></a><span style={{
      "fontWeight": "bold"
    }}>Ability Burn:</span> This is a
special form of <a href="abilitiesAndConditions.html#ability-damaged" style={{
      "color": "rgb(87, 158, 182)"
    }}>ability damage</a> that cannot be
magically or psionically healed. It is caused by the use of certain
psionic feats and powers. It returns only through natural healing.
    <a id="disease-cascade-flu"></a><p><span style={{
        "fontWeight": "bold"
      }}>Disease, Cascade Flu:</span>
Spread by brain moles and other vermin; <span style={{
        "fontStyle": "italic"
      }}>injury</span>; <span style={{
        "fontStyle": "italic"
      }}>DC</span> 13; <span style={{
        "fontStyle": "italic"
      }}>incubation</span> one day; <span style={{
        "fontStyle": "italic"
      }}>damage</span> psionic cascade.</p>
    <p>A psionic cascade is a loss of control over psionic abilities.
Using power points becomes dangerous for a character infected by
cascade flu, once the incubation period has run its course. Every time
an afflicted character manifests a power, she must make a DC 16 <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check. On a failed
check, a psionic cascade is triggered. The power operates normally, but
during the following round, without the character&rsquo;s volition, two
additional powers she knows manifest randomly, and their power cost is
deducted from the character&rsquo;s reserve. During the next round,
three
additional powers manifest, and so on, until all the psionic
character&rsquo;s power points are drained. Powers with a range of
personal
or touch always affect the diseased character. For other powers that
affect targets, roll d%: On a 01-50 result, the power affects the
diseased character, and 51-00 indicates that the power targets other
creatures in the vicinity. Psionic creatures (those that manifest their
powers without paying points) cascade until all the powers they know
have manifested at least twice.</p>
    <p>As with any disease, a psionic character who is injured or
attacked by a creature carrying a disease or parasite, or who otherwise
has contact with contaminated material, must make an immediate
Fortitude save. On a success, the disease fails to gain a foothold. On
a failure, the character takes damage (or incurs the specified effect)
after the incubation period. Once per day afterward, the afflicted
character must make a successful Fortitude save to avoid repeating the
damage. Two successful saving throws in a row indicate she has fought
off the disease.</p>
    <a id="disease-cerebral-parasites"></a><p><span style={{
        "fontWeight": "bold"
      }}>Disease, Cerebral Parasites:</span>
Spread by contact with infected psionic creatures; <span style={{
        "fontStyle": "italic"
      }}>contact</span>; <span style={{
        "fontStyle": "italic"
      }}>DC</span> 15; <span style={{
        "fontStyle": "italic"
      }}>incubation</span> 1d4 days; <span style={{
        "fontStyle": "italic"
      }}>damage</span> 1d8 power points. </p>
    <p>Cerebral parasites are tiny organisms, undetectable to normal
sight. An afflicted character may not even know he carries the
parasites - until he discovers he has fewer power points for the day
than expected. Psionic creatures with cerebral parasites are limited to
using each of their known powers only once per day (instead of freely
manifesting them). See the note about diseases under Cascade Flu, above.</p>
    <a id="negative-levels"></a><p><span style={{
        "fontWeight": "bold"
      }}>Negative Levels:</span>
Psionic characters can gain <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>negative levels</a> just like
members of other character classes. A psionic character loses access to
one power per negative level from the highest level of power he can
manifest; he also loses a number of power points equal to the cost of
that power. If two or more powers fit these criteria, the manifester
decides which one becomes inaccessible. The lost power becomes
available again as soon the negative level is removed, providing the
manifester is capable of using it at that time. Lost power points also
return.
    </p>
    <a id="psionic-spells"></a><h3>PSIONIC SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Listed below are arcane and divine spells that interact with psionics
as well as a cleric domain, Mind.
    <a id="bard-spells"></a><h6>BARD SPELLS</h6>
    <a id="second-level-bard-spells"></a><p>2nd-Level Bard Spell
      <a href="#glossolalia" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Glossolalia:</span></a> Cone-shaped shriek
distracts smart foes, makes concentrating difficult.</p>
    <a id="cleric-spells"></a><h6>CLERIC SPELLS</h6>
    <a id="third-level-cleric-spells"></a><p>3rd-Level Cleric Spell
      <a href="#telepathic-bond-lesser" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Telepathic Bond, Lesser:</span></a> As
telepathic bond, but you and one other creature.
    </p>
    <a id="fourth-level-cleric-spells"></a><p>4th-Level Cleric Spells
      <a href="#dweomer-of-transference" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Dweomer of Transference:</span></a> Convert
spellcasting into psionic power points.
    </p>
    <a id="fifth-level-cleric-spells"></a><p>5th-Level Cleric Spells
      <a href="#psychic-turmoil" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Psychic Turmoil:</span></a> Invisible field
leeches psionic power points away.
    </p>
    <a id="seventh-level-cleric-spells"></a><p>7th-Level Cleric Spells
      <a href="#psychic-turmoil-greater" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Psychic Turmoil, Greater:</span></a> As
psychic turmoil, but you gain power points as temporary hp.</p>
    <a id="eigth-level-cleric-spells"></a><p>8th-Level Cleric Spells
      <a href="#brain-spider" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Brain Spider:</span></a> Listen to thoughts
of up to eight other creatures.</p>
    <a id="mind-domain"></a><h6>MIND DOMAIN</h6>
Granted Power: Gain a +2 bonus on <a href="skillsAll.html#bluff" style={{
      "color": "rgb(87, 158, 182)"
    }}>Bluff</a>, <a href="skillsAll.html#diplomacy" style={{
      "color": "rgb(87, 158, 182)"
    }}>Diplomacy</a>,
and <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense Motive</a> checks. Gain a +2
bonus on Will saves against enchantment spells and effects.
    <p><span style={{
        "fontWeight": "bold"
      }}>Mind Domain Spells</span>
1 <a href="spellsC.html#comprehend-languages" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Comprehend Languages:</span></a> You
understand all spoken and written languages.
2 <a href="spellsDtoE.html#detect-thoughts" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Detect Thoughts:</span></a> Allows
&ldquo;listening&rdquo; to surface thoughts.
3 <a href="#telepathic-bond-lesser" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Telepathic Bond, Lesser:</span></a> Link
with subject within 30 ft. for 10 min./level.
4 <a href="spellsDtoE.html#discern-lies" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Discern Lies:</span></a> Reveals deliberate
falsehoods.
5 <a style={{
        "color": "black"
      }} href="spellsTtoZ.html#telepathic-bond"><span style={{
          "fontWeight": "bold"
        }}>Telepathic
Bond:</span></a> Link lets allies communicate.
6 <a href="#probe-thoughts" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Probe Thoughts:</span></a> Read subject&rsquo;s memories, one question/ round. 7 <a href="#brain-spider" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Brain Spider:</span></a> Eavesdrop on
thoughts of up to eight other creatures.
8 <a href="spellsMtoO.html#mind-blank" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Mind Blank:</span></a> Subject is immune to
mental/emotional magic and scrying.
9 <a href="spellsTtoZ.html#weird" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Weird:</span></a> Fearful illusion affects
all within 30 ft., either killing or dealing 3d6 damage.
    </p>
    <a id="sorcerer-wizard-spells"></a><h6>SORCERER/WIZARD SPELLS</h6>
    <a id="second-level-sorcerer-wizard-spells"></a><p>3rd-Level Sorcerer/Wizard Spell
      <a href="#telepathic-bond-lesser" style={{
        "color": "black"
      }}><span style={{
          "fontWeight": "bold"
        }}>Telepathic Bond, Lesser:</span></a> As
telepathic bond, but you and one other creature.
      <a id="fourth-level-sorcerer-wizard-spells"></a>4th-Level Sorcerer/Wizard Spell
      <a href="#dweomer-of-transference" style={{
        "color": "black",
        "fontWeight": "bold"
      }}>Dweomer of Transference:</a>
Convert spellcasting into psionic power points.
      <a id="fifth-level-sorcerer-wizard-spells"></a>5th-Level Sorcerer/Wizard Spell
      <a href="#psychic-turmoil" style={{
        "color": "black",
        "fontWeight": "bold"
      }}>Psychic Turmoil:</a>
Invisible field leeches psionic power points away.
    </p>
    <a id="sixth-level-sorcerer-wizard-spells"></a><span style={{
      "color": "black"
    }}>6th-Level Sorcerer/Wizard Spell</span>
    <a href="#mental-pinnacle" style={{
      "color": "black",
      "fontWeight": "bold"
    }}>Mental Pinnacle<sup>M</sup>:</a>
You gain the mental powers of a psion.
    <a href="#probe-thoughts" style={{
      "color": "black",
      "fontWeight": "bold"
    }}>Probe
Thoughts:</a>
Read subject&rsquo;s memories, one question/ round.
    <p> 
      <a id="seventh-level-sorcerer-wizard-spells"></a>7th-Level Sorcerer/Wizard Spell
      <a href="#psychic-turmoil-greater" style={{
        "color": "black",
        "fontWeight": "bold"
      }}>Psychic
Turmoil, Greater:</a> As psychic turmoil, but you gain power points as
temporary hit points.
    </p>
    <a id="spell-descriptions"></a><h5>SPELL DESCRIPTIONS</h5>
    <p>The following spell descriptions are presented in alphabetical
order.
    </p>
    <h6><a id="brain-spider"></a>Brain Spider</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Cleric 8, Mind 7
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, M, DF
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Long (400 ft. + 40
ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Targets:</span> Up to eight
living creatures
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>This spell allows you to eavesdrop as a standard action on the
thoughts
of up to eight other creatures at once, hearing as desired:</p>
    <ul>
      <li>
        <p>Individual trains of thought in whatever order you desire.</p>
      </li>
      <li>
        <p>Information from all minds about one particular topic,
thing, or
being, one nugget of information per caster level.</p>
      </li>
      <li>
        <p>A study of the thoughts and memories of one creature of
the group in
detail.</p>
      </li>
    </ul>
    <p>Once per round, if you do not perform a detailed study of one
creature&rsquo;s mind, you can attempt (as a standard action) to
implant a <a href="spellsS.html#suggestion" style={{
        "color": "rgb(87, 158, 182)"
      }}>suggestion</a>
in the mind of any
one of the affected creatures. The
creature can make another Will saving throw to resist the suggestion,
using the save DC of the brain spider spell. (Creatures with special
resistance to enchantment spells can use this resistance to keep from
being affected by the suggestion.) Success on this saving throw does
not negate the other effects of the brain spider spell for that
creature.</p>
    <p>You can affect all intelligent beings of your choice within
range (up
to the limit of eight), beginning with known or named beings. Language
is not a barrier, and you need not personally know the beings The spell
cannot reach those who make a successful Will save.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Material Component:</span> A
spider of any size or kind. It can be dead, but
must still have all eight legs.</p>
    <h6><a id="dweomer-of-transference"></a>Dweomer of Transference</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Cleric 4,
sorcerer/wizard 4
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One willing
psionic creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless)
    <p>With this spell, you form a radiating corona around the head
of a
psionic ally, then convert some of your spells into psionic power
points. When you finish casting dweomer of transference, a red-orange
glow surrounds the psionic creature&rsquo;s head. For the duration of
the
spell, any spells cast at the subject don&rsquo;t have their usual
effect,
instead converting themselves harmlessly into psionic energy that the
subject can use as energy for psionic powers. You can cast any spell
you like at the subject, even area spells, effect spells, and spells
for whom the subject would ordinarily not be a legitimate target. The
spells don&rsquo;t do anything other than provide the subject with
power
points, but you must still cast them normally, obeying the component
and range requirements listed in the description of each spell.</p>
    <p>For each spell you cast into the dweomer of transference, the
psionic
creature gets temporary power points, according to the following table.
The transference isn&rsquo;t perfectly efficient. The temporary power
points
acquired through a dweomer of transference dissipate after 1 hour if
they haven&rsquo;t already been spent.</p>
    <a id="table-dweomer-of-transference"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px",
            "verticalAlign": "bottom"
          }}>Spell
Level
          </th>
          <th style={{
            "width": "100px"
          }}>Power Points Acquired
          </th>
        </tr>
        <tr className="odd-row">
          <td>0
          </td>
          <td>0
          </td>
        </tr>
        <tr>
          <td>1st
          </td>
          <td>1
          </td>
        </tr>
        <tr className="odd-row">
          <td>2nd
          </td>
          <td>2
          </td>
        </tr>
        <tr>
          <td>3rd
          </td>
          <td>4
          </td>
        </tr>
        <tr className="odd-row">
          <td>4th
          </td>
          <td>6
          </td>
        </tr>
        <tr>
          <td>5th
          </td>
          <td>8
          </td>
        </tr>
        <tr className="odd-row">
          <td>6th
          </td>
          <td>10
          </td>
        </tr>
        <tr>
          <td>7th
          </td>
          <td>12
          </td>
        </tr>
        <tr className="odd-row">
          <td>8th
          </td>
          <td>14
          </td>
        </tr>
        <tr>
          <td className="last-row">9th
          </td>
          <td className="last-row">16
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="glossolalia"></a>Glossolalia</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Sonic,
Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Bard 2
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 60 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> Cone-shaped burst
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
partial; see text
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>You utter shrieks of nonsense and gibberish that thinking
creatures
find distracting and confusing. The spell affects only creatures with
Intelligence scores of 3 or higher. Any such creatures within the
spell&rsquo;s area must succeed on Fortitude saves or be <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round
(if they have Intelligence scores of 20 or higher), <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a> for 1 round
(if they have Intelligence scores between 10 and 19), or <a href="abilitiesAndConditions.html#shaken" style={{
        "color": "rgb(87, 158, 182)"
      }}>shaken</a> for 1
round (if they have Intelligence scores between 3 and 9). In addition,
a creature within the spell&rsquo;s area loses any psionic focus it
might
have had and takes a -4 penalty on <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> checks for 1
round.
These effects happen whether or not the creature succeeded on its save.</p>
    <h6><a id="mental-pinnacle"></a>Mental Pinnacle</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Sorcerer/wizard 6
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, M
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <p>For a brief time, you achieve the mental dominance of a
powerful psion,
able to lash out at enemies using only the power of your mind. Your
revel in your new mental powers to the point that you disdain using
spells, even in the form of effects from magic items. You gain a +4
enhancement bonus to Intelligence and Wisdom, 3 power points per caster
level, and access to the following powers. </p>
    <ul>
      <li>
        <p><a href="psionicPowersGtoP.html#mind-thrust" style={{
            "color": "rgb(87, 158, 182)"
          }}>Mind Thrust<sup>A</sup></a>: Deal
1d10 damage.</p>
      </li>
      <li>
        <p><a href="psionicPowersDtoF.html#ego-whip" style={{
            "color": "rgb(87, 158, 182)"
          }}>Ego Whip<sup>A</sup></a>: Deal 1d4
Cha damage and <a href="abilitiesAndConditions.html#dazed" style={{
            "color": "rgb(87, 158, 182)"
          }}>daze</a> for 1 round.</p>
      </li>
      <li>
        <p><a href="psionicPowersGtoP.html#psionic-blast" style={{
            "color": "rgb(87, 158, 182)"
          }}>Psionic Blast<sup>A</sup></a>: Stun
creatures in 30-ft. cone for 1 round.</p>
      </li>
      <li>
        <p><a href="psionicPowersGtoP.html#id-insinuation" style={{
            "color": "rgb(87, 158, 182)"
          }}>Id Insinuation:</a> Swift tendrils
of thought disrupt and <a href="abilitiesAndConditions.html#confused" style={{
            "color": "rgb(87, 158, 182)"
          }}>confuse</a> your
target.</p>
      </li>
      <li>
        <p><a href="psionicPowersGtoP.html#psychic-crush" style={{
            "color": "rgb(87, 158, 182)"
          }}>Psychic Crush<sup>A</sup></a>:
Brutally crush subject&rsquo;s mental essence, reducing
subject to -1 hit points.</p>
      </li>
    </ul>
    <p>You manifest the powers as a psion of your caster level does,
creating
displays as described in each power&rsquo;s description. You lose your
spellcasting ability, including your ability to use spell activation or
spell completion magic items, just as if those spells were no longer on
your class list. For the duration of this spell, you use magic items
and psionic items as if you were a psion with only the five powers
given above on your class list.</p>
    <p>Any unspent power points dissipate when the spell ends.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Material Component:</span> A
potion of fox&rsquo;s cunning, which you drink (its
effect is overridden by the effect of this spell).</p>
    <h6><a id="probe-thoughts"></a>Probe Thoughts</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Mind 6, Wiz/Sor 6
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates; see text
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>All the subject&rsquo;s memories and knowledge are accessible
to
you, ranging
from memories deep below the surface to those still easily called to
mind. You can learn the answer to one question per round, to the best
of the subject&rsquo;s knowledge. You can also probe a sleeping
subject,
though the subject may make a Will save against the DC of the probe
thoughts spell to wake after each question. Subjects who do not wish to
be probed can attempt to move beyond the power&rsquo;s range, unless
somehow
hindered. You pose the questions telepathically, and the answers to
those questions are imparted directly to your mind. You and the target
do not need to speak the same language, though less intelligent
creatures may yield up only appropriate visual images in answer to your
questions.</p>
    <h6><a id="psychic-turmoil"></a>Psychic Turmoil</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Cleric 5,
sorcerer/wizard 5
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, M
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Area: </span>40-ft.-radius
emanation centered on a point in space
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
partial; see text
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>With this spell, you create an invisible field that leeches
away the
power points of psionic characters standing within the emanation.
Nonpsionic characters are unaffected.</p>
    <p>When the spell is cast and at the beginning of each of your
subsequent
turns, psionic creatures within the area of the psychic turmoil lose 1
power point per manifester level they have. Characters who succeed on a
Will save when they first come into contact with the emanation lose
only half as many power points (round down) each round. Characters get
only one save attempt against any particular psychic turmoil effect,
even if they leave the spell&rsquo;s area and later return.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Material Component:</span>
Five playing cards, which are torn in half when the
spell is cast.</p>
    <h6><a id="psychic-turmoil-greater"></a>Psychic Turmoil, Greater</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Cleric 7,
sorcerer/wizard 7
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <p>As <a href="#psychic-turmoil" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic turmoil</a>, except you gain
1 temporary hit point for each
power point the spell takes from a psionic creature. The temporary hit
points last for 1 hour.
    </p>
    <h6><a id="telepathic-bond-lesser"></a>Telepathic Bond, Lesser</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Cleric 3, Mind 3,
sorcerer/wizard 3
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 30 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Targets:</span> You and one
willing creature within 30 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 10 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p className="initial">You forge a telepathic bond with another
creature with an Intelligence
score of 6 or higher. The bond can be established only with a willing
subject. You can communicate telepathically through the bond regardless
of language. No special power or influence is established as a result
of the bond. Once the bond is formed, it works over any distance
(although not from one plane to another).
    </p>
    <p className="initial">
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      